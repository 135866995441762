const translationuz = {
  mm: "oy",
  dd: "kun",
  yyyy: "yil",
  logout: "Chiqish",
  bargain: "Narx muzokarasi",
  main: "Bosh sahifa",
  success_created: "Muvaffaqiyatli e’loningiz\ntekshiruvga yuborildi!",
  reject_message: "Hatolik yuz berdi!\nKeynroq urinib ko’ring",
  // general
  intro_title: "Keling sizning orzuyingizdagi\nuyni topaylik!",
  slider_title: "Turar joy majmualari",
  top_announcement: "Top E’lonlar",
  search: "Qidirish",
  create_announcement: "E'lon joylash",
  register: "Ro'yxatdan o'tish",
  cabinet: "Shaxsiy kabinet",
  // Register
  room_floor: "Qavatni tanlash",
  enter_your_info: "Ma'lumotlaringizni kiriting",
  name: "Ismingiz",
  surname: "Familiyangiz",
  oferta: "Ofertani tasdiqlash!",
  account: "Hisobingiz bormi?",
  login: "Hisobga kirish",
  sms: "Sms kod yuborish",
  royxat: "Royxatdan o'tish",
  xali: "Xali xisob ochmadingizmi",
  kodini: "Tasdiqlash kodini kiriting! ",
  new: "Yangi",
  kodkelmadi: "Kod kelmadimi?",
  qaytayuborish: "Qayta yuborish",
  yuborish: "Yuborish",
  siz: "Siz muvaffaqiyatli ro’yxatdan o’tdingiz!",
  bosh: "Bosh menyu",

  // months
  january: "Yanvar",
  february: "Fevral",
  march: "Mart",
  april: "Aprel",
  may: "May",
  june: "Iyun",
  july: "Iyul",
  august: "Avgust",
  september: "Sentabr",
  october: "Oktabr",
  november: "Noyabr",
  december: "Dekabr",

  // Footer
  footer1: "Biz haqimizda",
  footer2: "E'lon qo'shish",
  footer3: "Shaxsiy kabinet",
  connect_with_us: "Biz bilan bog'laning",
  location: "Namangan viloyati, chust tumani, kamarsada",
  social: "Bizning ijtimoiy tarmoqlarimiz",
  // create
  select_type: "Turini tanlang",
  select_place_type: "Joy turini tanlang",
  select_type_sale: "Sotuv turini tanlang",
  sotix: "Sotix",
  majmuo: "Turar joy majmualari",
  quruq: "Quruq yer",
  repairment: "Ta'mir holati",
  bad: "Yomon",
  norm: "O'rtacha",
  good: "Yaxshi",
  flat: "Kvartira",
  home: "Xonadon",
  price: "Narx",
  business_place: "Biznes uchun joy",
  payment: "Oldindan to'lov",
  exist: "Bor",
  not_exist: "Yo'q",
  rent_out: "Ijaraga berish",
  sell: "Sotish",
  select_month: "Oyni tanlash",
  delete_announcement: "E'lonni o'chirish",
  delete_announcement_text: "Nima sababdan e’lonni o’chirmoqchisiz?",
  delete_announcement_reasons:
    "Uy sotildi., Fikrimdan qaytdim., {{date}} vaqtdan beri hech kim bog'lanmadi., Boshqa sabab:",
  delete_announcement_reason: "Sababini yozing...",
  month: "Oy",
  general_info: "Umumiy ma'lumot",
  extra_info: "Qo'shimcha ma'lumot",
  rooms: "Xonalar soni",
  year_built: "Qurilgan yil",
  whole_place: "Umumiy joy",
  extra_comfort: "Qo’shimcha qulayliklar",
  gas: "Gaz",
  water: "Suv",
  electr: "Elektr energiya",
  internet: "Internet",
  conditioner: "Konditsioner",
  fridge: "Muzlatgich",
  tv: "Televizor",
  washing_machine: "Kiryuvish mashinasi",
  where: "Qayerda joylashgan",
  where_situated_placeholder: "Namangan,Davlatobod,5-kichik Noxiya 1-uy",
  description: "Tavsifi",
  save: "Saqlash",
  add_photo: "Rasm joylash",
  img10: "10ta rasm gacha/ 10megabaytdan katta bo'masligi kerak",
  // single page
  your_account: "Sizning hisob raqamingiz",
  extra: "Qo'shimcha",
  repair: "Tamir",
  narx: "Narx",
  oldindantolov: "Oldindan tolov",
  umumiy: "Umumiy malumot",
  qoshimcha: "Qo’shimcha qulayliklar",
  bath: "Dush",
  xonalar: "Xonalar soni",
  joy: "Umumiy joy",
  kelishish: "Narxni kelishiladimi",
  elonlar: "O'xshash elonlar",
  qavat: "qavat",
  balcony: "Balkon",
  qurulganyil: "Qurulgan yili",
  appearence: "Oynadan ko'rinish",
  lift: "Lift",
  similar_adds: "O'xshash e'lonlar",
  ha: "Ha",
  yoq: "Yoq",
  example: "Namuna",
  incorrect_code: "Tasdiqlash kodi noto’g’ri!",
  // filter
  sort: "Saralash",
  popular: "Ommabop",
  cheap: "Arzonzoq",
  expensive: "Qimmat",
  //joy turini tanlang
  place: "Joy turini tanlang",
  buy: "Sotib olish",
  rent: "Ijaraga olish",
  close: "Yopish",
  demo: "Sayt hozirda bepul rejimida ishlamoqda. Bemalol bepul e’lon joylashingiz mumkin!",
  tamir: "Ta’mir holati",

  // user
  private_info: "Shaxsiy malumotlarim",
  announcement: "E'lonlarim",
  favorites: "Sevimlilar",
  fill: "To'ldirish",
  street: "MFY",
  wer_glad: "frossh.uz saytini tanlaganingizdan mamnunmiz!",
  must_register: "Tizimga kirishingiz kerak!",
  enter_register: "Tizimga kirish",
  viloyat: "Viloyat:",
  shaxar: "Shahar:",
  xona: "xona",
  // payme
  payment_type_sel: "To’lov turini tanlang",
  enter_emount: "To’lov summasini kiriting",
  proceed_payment: "To'lovga o'tish",
  // filter
  regions: "Viloyatlar",
  close: "Yopish",
  additional_comfort: "Qo`shimcha qulayliklar",
  close: "Yopish",
  elon: "E'lonlar topilmadi",
  announcement_favorite: "E'lon sevimlilarga qo'shildi",
  announcement_unfavorite: "E'lon sevimlilardan o'chirildi",
  announcement_favorite_error: "E'lonni sevimlilarga qo'shishda xatolik",
  //
  tekshirilmoqda: "Tekshirilmoqda...",
  Quyidagi: "Quyidagi fayllar 10 MB dan katta:",
  have_not_account: "Hali hisob ochmadingizmi?",
  create_account: "Hisob ochish",
  write: "Yozib kiritish",
  Nothingfound: "Hech narsa topilmadi",
  max_img_length: "Maksimal rasm soni 10",
};

export default translationuz;
