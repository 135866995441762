const translationru = {
  mm: "мм",
  dd: "дд",
  yyyy: "гггг",
  logout: "Выход",
  bargain: "Торговаться",
  main: "Главная",
  success_created: "Ваше успешное объявление\nотправлено на проверку!",
  reject_message: "Произошла ошибка!\nПопробуйте позже",
  // general
  intro_title: "Давайте найдем дом\nвашей мечты!",
  slider_title: "Жилые комплексы",
  top_announcement: "Лучшие объявления",
  search: "поиск",
  create_announcement: "Разместить ",
  register: "Зарегистрироваться",
  cabinet: "Личный кабинет",
  // Register
  announcement_favorite_error: "Ошибка добавления объявления в избранное",
  announcement_favorite: "Объявление добавлено в избранное",
  announcement_unfavorite: "Объявление удалено из избранного",
  room_floor: "Выберите этаж",
  enter_your_info: "Введите свои учетные данные",
  name: "Имя",
  new: "Новый",
  surname: "Фамилия",
  oferta: "Подтверждение предложения!",
  account: "У тебя есть аккаунт?",
  login: "Войти в аккаунт",
  royxat: "Зарегистрироваться",
  sms: "Отправить СМС-код",
  kodkelmadi: "Код не пришел ?",
  qaytayuborish: "Повторная отправка",
  yuborish: "Отправить",
  siz: "Вы успешно  вошли в систему!",
  bosh: "Главное меню",

  demo: "Сайт в настоящее время работает в бесплатном режиме. Вы можете легко разместить бесплатное объявление!",
  // months
  january: "январь",
  february: "февраль",
  march: "Март",
  april: "апрель",
  may: "Май",
  june: "Июнь",
  july: "Июль",
  august: "Август",
  september: "Сентябрь",
  october: "Октябрь",
  november: "ноябрь",
  december: "Декабрь",

  // Footer
  footer1: "о нас",
  footer2: "Добавить объявление",
  footer3: "Личный кабинет",
  connect_with_us: "Связаться с нами",
  location: "Камарсада Чустского района Наманганской области.",
  social: "Наши социальные сети",
  // create
  select_type: "Выберите тип",
  select_type_sale: "Тип продажи",
  select_place_type: "Тип местоположения",
  sotix: "Сотих",
  quruq: "Сухая земля",
  repairment: "Статус ремонта",
  bad: "Плохо",
  norm: "Средний",
  good: "Хороший",
  majmuo: "Жилой комплекс",
  flat: "Квартира",
  home: "Дом",
  price: "Цена",
  business_place: "Место для бизнеса",
  payment: "Предоплата",
  payment_type_sel: "Выберите тип оплаты",
  exist: "Есть",
  not_exist: "Нет",
  rent_out: "Сдать в аренду",
  sell: "Продажа",
  select_month: "Выберите месяц",
  delete_announcement: "Удалить объявление",
  delete_announcement_text: "По какой причине вы хотите удалить объявление?",
  delete_announcement_reasons:
    "Продано., Я передумал., С {{date}} никто не связывался., Другое:",
  delete_announcement_reason: "Запишите причину...",
  month: "Месяц",
  general_info: "Общая информация",
  extra_info: "Дополнительная информация",
  rooms: "Количество комнат",
  year_built: "Год постройки",
  whole_place: "Общее место",
  extra_comfort: "Дополнительные удобства",
  gas: "Газ",
  water: "Вода",
  electr: "Электрическая энергия",
  internet: "Интернет",
  conditioner: "Кондиционер",
  fridge: "Холодильник",
  tv: "телевизор",
  washing_machine: "Стиральная машина",
  where: "Где он находится?",
  where_situated_placeholder: "Наманган, Давлатабад, 5-малая Нохия 1 дом",
  description: "Описание",
  save: "Сохранять",
  add_photo: "Опубликовать изображение",
  img10: "До 10 фотографий/ Он не должен быть больше 10 мегабайт",
  // single page
  your_account: "Ваш номер счета",
  narx: "цена",
  oldindantolov: "предоплата",
  extra: "Дополнительный",
  joy: "Общее место",
  kelishish: "Цена договорная?",
  qurulganyil: "год постройки",
  elonlar: "Похожие объявления",
  xonalar: "Количество комнат",
  umumiy: "Общая информацияt",
  qoshimcha: "Дополнительные удобства",
  ha: "Да",
  yoq: "Heт",
  incorrect_code: "Неправильный код подтверждения!",
  example: "Пример",
  repair: "Ремонт",
  bath: "Душ",
  balcony: "Балкон",
  appearence: "Вид из зеркала",
  lift: "Лифт",
  similar_adds: "Похожие объявления",
  // filter
  sort: "Сортировка",
  popular: "Популярный",
  cheap: "Более дешевый",
  expensive: "Дорогой",
  close: "закрывать",
  place: " тип место",
  buy: "Покупка",
  rent: "Аренда",
  tamir: "Статус ремонта",
  regions: "регионы",
  additional_comfort: "Дополнительные ",
  elon: "Объявления не найдены",
  //user
  private_info: "Мои личные данные",
  announcement: "Мои объявления",
  favorites: "Избранное",
  fill: "Заполнить",
  street: "Улица",
  wer_glad: "Мы рады, что выбрали сайт frossh.uz!",
  must_register: "Необходимо войти в систему!",
  enter_register: "Войти в систему",
  enter_emount: "Введите сумму",
  viloyat: "Провинция:",
  proceed_payment: "Продолжить оплату",
  shaxar: "Город:",
  xona: "комната",
  qavat: "этаж",
  //

  tekshirilmoqda: "Проверка...",
  Quyidagi: "Следующие файлы имеют размер более 10 МБ:",
  have_not_account: "У вас нет аккаунта?",
  create_account: "Создать аккаунт",
  write: "Запись",
  Nothingfound: "Ничего не найдено",
  kodini: "Введите код подтверждения",
  max_img_length: "Максимальное количество изображений 10",
};

export default translationru;
